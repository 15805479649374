var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 14 },"colon":false,"form":_vm.form}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"会议名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'name',
                            {
                                initialValue: _vm.detail.name,
                                rules: [
                                    { required: true, message: '请输入后选择！' },
                                ],
                            },
                        ]),expression:"[\n                            'name',\n                            {\n                                initialValue: detail.name,\n                                rules: [\n                                    { required: true, message: '请输入后选择！' },\n                                ],\n                            },\n                        ]"}],staticStyle:{"width":"200px"},attrs:{"disabled":true,"placeholder":"请输入任务名称"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"会议时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'meetingTime',
                            {
                                initialValue: _vm.detail.meetingTime,
                                rules: [
                                    { required: true, message: '请输入后选择！' },
                                ],
                            },
                        ]),expression:"[\n                            'meetingTime',\n                            {\n                                initialValue: detail.meetingTime,\n                                rules: [\n                                    { required: true, message: '请输入后选择！' },\n                                ],\n                            },\n                        ]"}],staticStyle:{"width":"200px"},attrs:{"disabled":true}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"会议地点"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'place',
                            {
                                initialValue: _vm.detail.place,
                                rules: [
                                    { required: true, message: '请输入后选择！' },
                                ],
                            },
                        ]),expression:"[\n                            'place',\n                            {\n                                initialValue: detail.place,\n                                rules: [\n                                    { required: true, message: '请输入后选择！' },\n                                ],\n                            },\n                        ]"}],staticStyle:{"width":"200px"},attrs:{"disabled":true}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"签发对象"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'issueObject',
                            {
                                initialValue: _vm.detail.issueObject,
                                rules: [
                                    { required: true, message: '请输入后选择！' },
                                ],
                            },
                        ]),expression:"[\n                            'issueObject',\n                            {\n                                initialValue: detail.issueObject,\n                                rules: [\n                                    { required: true, message: '请输入后选择！' },\n                                ],\n                            },\n                        ]"}],staticStyle:{"width":"200px"},attrs:{"disabled":true}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"决议事项"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'decisionItems',
                            {
                                initialValue: _vm.detail.decisionItems,
                                rules: [
                                    { required: true, message: '请输入后选择！' },
                                ],
                            },
                        ]),expression:"[\n                            'decisionItems',\n                            {\n                                initialValue: detail.decisionItems,\n                                rules: [\n                                    { required: true, message: '请输入后选择！' },\n                                ],\n                            },\n                        ]"}],staticStyle:{"width":"200px"},attrs:{"disabled":true}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }